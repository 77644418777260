import * as React from "react";

import Tail from "../components/tail";
import Header2 from "../components/header/header2";
import Header3 from "../components/header/header3";
import Header4 from "../components/header/header4";
import MGCaption from "../components/MGCaption";
import { ColorsLong, fFloatG } from "../components/helper";
import ImageSlider from "../components/ImageSlider";
import { StaticImage } from "gatsby-plugin-image";
import PieOne from "../components/PieOne";
import VKPost from "../components/VKPost";
import { Link } from "gatsby";

const firstPie = [
  {
    label:
      "Опровержения пресс-службой АО «МОСГАЗ» инфоповодов об инцидентах с газом в столице",
    value: 7,
  },
  { label: "ТО ВДГО, борьба со лжегазовиками, Служба сервиса", value: 34 },
  { label: "ФКР", value: 6 },
  {
    label: "Готовность газовых систем Москвы к зимнему сезону",
    value: 8,
  },
  { label: "Профилактика Вечного огня в Александровском саду", value: 4 },
  {
    label: "Реконструкция и строительство газовых сетей инфраструктуры",
    value: 22,
  },
  {
    label: "Международный конкурс «Рисуем с детьми Вечный огонь»",
    value: 3,
  },
  {
    label: "Украшение спецтехники в преддверии Нового года.",
    value: 1,
  },
  {
    label:
      "X фестиваль «Волшебная сила голубого потока — МОСГАЗ зажигает звезды»",
    value: 13,
  },
];

const secondPie = [
  { label: "МОСГАЗ (mos-gaz.ru)", value: 17 },
  {
    label: "Россия 1 и Россия 24",
    value: 12,
  },
  { label: "Официальный сайт Мэра Москвы (mos.ru)", value: 11 },
  {
    label: "Москва 24",
    value: 6,
  },
  {
    label: "ТАСС",
    value: 6,
  },
  {
    label: "РИАМО",
    value: 6,
  },
  {
    label: "АГН Москва",
    value: 5,
  },
  {
    label: "Вечерняя Москва",
    value: 4,
  },
  {
    label: "Российская газета",
    value: 4,
  },
  {
    label: "Информационный центр Правительства Москвы (icmos.ru)",
    value: 3,
  },
  {
    label: "Телеканал 360 (360tv.ru)",
    value: 2,
  },
  {
    label: "Рен ТВ",
    value: 2,
  },
  {
    label: "РИА Новости (Недвижимость и РИА закрытая лента)",
    value: 21,
  },
];

const data = ColorsLong;
const Tech = () => (
  <Tail>
    <div className="m-auto max-w-5xl p-10">
      <h1>tech: delete me before release</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>

      <VKPost
        elementId={"vk1"}
        ownerId={265870743}
        postId={696270}
        hash={"897x7sHjALxtKAc-F_ehmMNI-w"}
      >
        <Link to="https://vk.com">
          <StaticImage
            className="hidden sm:block"
            loading="lazy"
            src="../images/fallback/vk.2021.02.20.png"
          />
        </Link>
      </VKPost>

      <Header2>Съешь этих вкусных французских булок</Header2>
      <Header3>Съешь этих вкусных французских булок</Header3>
      <Header4>Съешь этих вкусных французских булок</Header4>
      <p>Съешь этих вкусных французских булок</p>
    </div>
    <ImageSlider>
      <div className="max-h-[300px] pr-5">
        <StaticImage
          src="../images/part-01/slide.1/2Z4A9430.jpg"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          height={300}
        />
      </div>
      <div className="max-h-[300px] pr-5">
        <StaticImage
          src="../images/part-01/slide.1/2Z4A8898.jpg"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          height={300}
        />
      </div>
      <div className="max-h-[300px] pr-5">
        <StaticImage
          src="../images/part-01/slide.1/2Z4A9561.crop.1.jpg"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          height={300}
        />
      </div>
      <div className="max-h-[300px] pr-5">
        <StaticImage
          src="../images/part-01/slide.1/120A0624.jpg"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          height={300}
        />
      </div>
    </ImageSlider>
    <div className="m-auto max-w-5xl p-10">
      <MGCaption>Съешь этих вкусных французских булок</MGCaption>
      <p>Съешь этих вкусных французских булок</p>

      <Header2>Съешь этих вкусных французских булок</Header2>
      <p>Съешь этих вкусных французских булок</p>

      <Header3>Съешь этих вкусных французских булок</Header3>
      <p>Съешь этих вкусных французских булок</p>

      <Header4>Съешь этих вкусных французских булок</Header4>
      <p>Съешь этих вкусных французских булок</p>

      {data.map((el, i) => (
        <li key={i}>
          <span
            className={`legend-pseudo `}
            style={{ "background-color": el }}
          ></span>
          {el}
        </li>
      ))}

      <PieOne data={firstPie} idkey={"po1"} pieColors={ColorsLong} />

      <PieOne data={secondPie} idkey={"po2"} pieColors={ColorsLong} />

      <p>{fFloatG(33.3)}</p>
      <p>{fFloatG(33.0)}</p>
      <p>{fFloatG(33.33)}</p>
      <p>{fFloatG(30.0)}</p>
      <p>{fFloatG(30)}</p>
      <p>{fFloatG(30_000)}</p>
      <p>{fFloatG(30_000, 2)}</p>
      <p>{fFloatG(30.0, 3)}</p>
    </div>
  </Tail>
);

export default Tech;
